<template>
  <div>
    <div class="flex items-start mt-5 mt-base mb-4">
      <h5 class="mt-1 font-medium">Usages</h5>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="usageList"
      :gridOptions="gridOptions"
      :animateRows="true"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

  </div>
</template>

<script>

import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue
  },
  props: {
    usageList: { type: Array, required: true, default: [] },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
      },
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    // this.balanceList = [...this.data]
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Order Id', field: 'orderId', width: 120, suppressSizeToFit: true },
      { headerName: 'Amount', field: 'amount', width: 150, suppressSizeToFit: true },
      { 
        headerName: 'Created',
        field: 'createdDate',
        sortable: true,
        width: 150, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat),
        suppressSizeToFit: false
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
    
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
  }
}
</script>

<style>

</style>