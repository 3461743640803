  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      
      <div v-if="giftVoucherId" class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <p class="w-full mb-3">{{ form.code }}</p>
        </div>
      </div>
      

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:50'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Customer</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="flex flex-wrap justify-start">
            <div style="width:90%">
              <v-select 
              :disabled='!customerOptions || customerOptions.length == 0'
              v-model="form.customerId" 
              :reduce="(option) => option.code" 
              name="Customer" 
              :options="customerOptions" :clearable="false"
              v-validate="''" />

              <span class="text-danger text-sm">{{ errors.first('Customer') }}</span>
              <span v-if='!customerOptions || customerOptions.length == 1' class="text-sm">Loading customer names please wait...</span>
              <span v-if='isReloadingCustomerNames' class="text-sm">Reloading customer names...</span>
            </div>

            <div>
              <vs-button type="border" icon-pack="feather" icon="icon-refresh-cw" class="ml-2" title="Refresh" @click="handleCustomerLoad">
              </vs-button>
            </div>
          </div>

        </div>
      </div>

      

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select 
            v-model="form.currencyCode" 
            :reduce="(option) => option.code" 
            name="Currency" 
            :options="currencyOptions" :clearable="false"
            v-validate="'required'" />
            <span class="text-danger text-sm">{{ errors.first('Currency') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Start Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <flat-pickr name="Start Date" v-model="form.startDate" :config="{ dateFormat: 'Y-m-d' }" class="w-full" />
          <!-- <span class="text-danger text-sm">{{ errors.first('Start Date') }}</span> -->
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Expiry Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <flat-pickr name="Start Date" v-model="form.expiryDate" :config="{ dateFormat: 'Y-m-d' }" class="w-full" />
          <!-- <span class="text-danger text-sm">{{ errors.first('Start Date') }}</span> -->
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Internal Reference</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.internalReference" v-validate="'max:25'" name="Internal Reference" />
          <span class="text-danger text-sm">{{ errors.first('Internal Reference') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-switch
            class=""
            v-model="form.isActive"
          />
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
        <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>

      <balance-list
        v-if="giftVoucherId"
        :balanceList="balanceList"
        @onBalanceAdded="handleBalanceAdded"
        @onBalanceEdited="handleBalanceEdited"
      />

      <div v-if="giftVoucherId" class="vx-row mb-4">
        <div class="vx-col w-1/5">
          <span>Total Balance:</span>
        </div>
        <div class="vx-col w-4/5">
          <div class="ml-3"><strong>{{totalBalance}} {{form.currencyCode}}</strong></div>
        </div>
      </div>

      <usage-list
        v-if="giftVoucherId"
        :usageList="usageList"
      />

    </vx-card>

  </travio-center-container>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'
import BalanceList from './components/BalanceList.vue'
import UsageList from './components/UsageList.vue'
import giftVoucherStore from "./giftVoucherStore";

export default {
  components: {
    flatPickr,
    BalanceList,
    UsageList,
  },
  props: {
    applicationId: { required: true },
    giftVoucherId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        code: '',
        name: '',
        customerId: 0,
        currencyCode: null,
        startDate: null,
        expiryDate: null,
        internalReference: '',
        isActive: true,
      }),
      balanceList: [],
      usageList: [],
      currencyOptions: [],
      balances: [],
      customerOptions: [{label: 'Any Customer', code: 0}],
      isReloadingCustomerNames: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.giftVoucherId == 0 ? 'Add Gift Voucher' : 'Edit Gift Voucher'
    },
    customerNames() {       
      return this.$store.state.giftVoucherStore.customerNames;
    },
    totalBalance() {
      return this.balanceList.reduce((runningSum, x) => runningSum + x.amount, 0)
    }
  },
  async created () {
    if (!giftVoucherStore.isRegistered) {
      this.$store.registerModule("giftVoucherStore", giftVoucherStore);
      giftVoucherStore.isRegistered = true;
    }

    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    
    
    this.$vs.loading()
    try {
      const formDataPromise = await this.$http.get(`api/giftVouchers/apps/${this.applicationId}/formData`)
      if (this.giftVoucherId) {
        // Edit

        const giftVoucherPromise = await this.$http.get(`api/giftVouchers/apps/${this.applicationId}/${this.giftVoucherId}`)
        const balancesPromise = await this.$http.get(`api/giftVouchers/apps/${this.applicationId}/${this.giftVoucherId}/balances`)
        const usagesPromise = await this.$http.get(`api/giftVouchers/apps/${this.applicationId}/${this.giftVoucherId}/usages`)

        const [giftVoucherResponse, balancesResponse, usagesResponse, formDataResponse] = await Promise.all([giftVoucherPromise, balancesPromise, usagesPromise, formDataPromise])
        
        this.form = Object.assign(this.form, giftVoucherResponse.data)

        if(this.form.customerId == null) {
          this.form.customerId = 0
        }

        this.balanceList = [...balancesResponse.data]
        this.usageList = [...usagesResponse.data]
        this.currencyOptions = formDataResponse.data.currencies || []
        // this.customerOptions = formDataResponse.data.customers || []
        
      } else {
        // Add
        const formDataResponse = await formDataPromise
        this.currencyOptions = formDataResponse.data.currencies || []
        // this.customerOptions = formDataResponse.data.customers || []
        this.form.currencyCode = formDataResponse.data.appDefaultCurrency || null
        // this.form.customerId = 0
      }
      

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close()
    if (!this.customerNames || this.customerNames.length == 0) {
      this.loadCustomerNames({
        applicationId: this.$route.params.applicationId,
        callback: () => { 
          this.customerOptions = [{label: 'Any Customer', code: 0}, ...this.customerNames]
        }
      })
    } else {
      this.customerOptions = [{label: 'Any Customer', code: 0}, ...this.customerNames]
    }
    
  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      this.$vs.loading()
      if (!this.giftVoucherId) {
        this.form.customerId = null
        try {
          await this.$http.post(`api/giftVouchers/apps/${this.applicationId}`, this.form.data())
          this.$_notifySuccess('Successfully added gifft voucher');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      } else {
        try {
          if (this.form.customerId == 0) {
            // 'Any Customer' option has a customer id of 0, but we'll store it as null in db
            this.form.customerId = null
          }
          await this.$http.put(`api/giftVouchers/apps/${this.applicationId}`, this.form.data())
          this.$_notifySuccess('Successfully updated gift voucher');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      }
      
      this.$vs.loading.close();
      this.onCancel()
    },
    async handleBalanceAdded(data) {
      this.$vs.loading()
      try {
        await this.$http.post(`api/giftVouchers/apps/${this.applicationId}/${this.giftVoucherId}/balances`, data)
        // Udate list to get the created date from server
        const balances = await this.$http.get(`api/giftVouchers/apps/${this.applicationId}/${this.giftVoucherId}/balances`)
        this.balanceList = [...balances.data]
        this.$_notifySuccess('Successfully added gift voucher balance');
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close();
      
    },
    async handleBalanceEdited(data) {
      this.$vs.loading()
      try {
        await this.$http.put(`api/giftVouchers/apps/${this.applicationId}/${this.giftVoucherId}/balances`, data)
        let updatedBalance = this.balanceList.find(x => x.id == data.id)
        updatedBalance = Object.assign(updatedBalance, data)
        this.balanceList = [...this.balanceList]
        this.$_notifySuccess('Successfully updated gift voucher balance');
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close();
    },
    onCancel() {
      this.$router.push({ name: 'application-tools-gift-vouchers', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    handleCustomerLoad() {
      this.isReloadingCustomerNames = true
      this.loadCustomerNames({ 
        applicationId: this.$route.params.applicationId,
        callback: () => { 
          this.isReloadingCustomerNames = false
          this.customerOptions = [{label: 'Any Customer', code: 0}, ...this.customerNames]
        }
      })
    },
    loadCustomerNames (payload) {
      this.$store
        .dispatch("giftVoucherStore/fetchCustomerNames", payload)
        .catch((err) => console.error(err))
        .finally();
      }
   
  }
}
</script>

<style>

</style>
