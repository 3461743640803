<template>
  <div>
    <div class="flex items-start mt-4 mt-base mb-4">
      <h5 class="mt-1 font-medium">Balances</h5>
      <vs-button class="ml-4" color="primary" @click="onAddBalance" type="border" size="small">Add</vs-button>      
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="balanceList"
      :gridOptions="gridOptions"
      :animateRows="true"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <balance-editor-modal v-if="showModal"
      :data="balanceInEdit"
      :isNew="isModalAdding"
      @onEdit="handleEdit"
      @onAdd="handleAdd"
      @onCancel="handleOnCancel"
    />

  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererBalanceActions from '../cell-renderers/CellRendererBalanceActions.vue'
import CellRendererBalanceActiveCheckbox from '../cell-renderers/CellRendererBalanceActiveCheckbox.vue'
import BalanceEditorModal from './BalanceEditorModal.vue'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
    CellRendererBalanceActiveCheckbox,
    CellRendererBalanceActions,
    BalanceEditorModal,
  },
  props: {
    balanceList: { type: Array, required: true, default: [] },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererBalanceActiveCheckbox,
        CellRendererBalanceActions
      },
      context: null,
      showModal: false,
      isModalAdding: true,
      balanceInEdit: null
      // contentList: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    // this.balanceList = [...this.data]
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { headerName: '', width: 60,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererBalanceActions) },
      { headerName: 'Id', field: 'id', width: 90, suppressSizeToFit: true },
      { headerName: 'Amount', field: 'amount', width: 120, suppressSizeToFit: true },
      { headerName: 'Reference', field: 'reference', width: 180, suppressSizeToFit: true },
      { headerName: 'Active', width: 100,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererBalanceActiveCheckbox), suppressSizeToFit: true },
      { 
        headerName: 'Created',
        field: 'createdDate',
        sortable: true,
        width: 150, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat),
        suppressSizeToFit: false
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
    
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAddBalance () {
      this.showModal = true
      this.isModalAdding = true
    },
    onEditBalance (data) {
      this.balanceInEdit = Object.assign({}, data)
      this.showModal = true
      this.isModalAdding = false
      
    },
    async handleEdit(data) {
      this.showModal = false
      this.balanceInEdit = null
      this.$emit('onBalanceEdited', data)
    },
    async handleAdd(data) {
      this.showModal = false
      this.$emit('onBalanceAdded', data)
    },
    async handleOnCancel() {
      this.showModal = false
      this.balanceInEdit = null
    },
  }
}
</script>

<style>

</style>