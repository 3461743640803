<template>
  <travio-modal width='500' height="225" zIndex="99999">
    <h3 slot="header">{{headingTitle}}</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Amount</label>
          <vs-input class="w-full" name="Amount" type="number" min="0" v-model.number="balance.amount" v-validate="'required'" />
          <div class="mt-1">
            <span class="text-danger text-sm">{{ errors.first('Amount') }}</span>
          </div>
        </div>

        
        <div class="vx-col w-full mt-4">
          <label class="">Reference</label>
          <vs-input class="w-full" name="Reference" v-model="balance.reference" v-validate="'max:25'" />
          <div class="mt-1">
            <span class="text-danger text-sm">{{ errors.first('Reference') }}</span>
          </div>
        </div>

        <div class="vx-col w-full mt-2">
          <vs-checkbox class="w-full mt-2" v-model="balance.isActive">Active</vs-checkbox>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveBalance" type="filled">{{buttonText}}</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import debounce from 'lodash/debounce'

export default {
  components: {
    TravioModal,
  },
  data () {
    return {
      balance: {},
    }
  },
  props: {
    data: { required: false, type: Object }, 
    isNew:  { required: true, type: Boolean },
  },
  computed: {
    headingTitle() {
      return this.balance.id == 0 ? 'Add Balance': 'Edit Balance'
    },
    buttonText() {
      return this.isNew ? 'Add': 'Update'
    }
  },
  created () {
  
    
  },
  mounted () {
    if(this.data && this.data.id) {
      // Edit
      this.balance = Object.assign({}, this.data)
    } else {
      // Add
      this.resetFormData()
    }
  },
  methods: {
    async saveBalance () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      if(this.balance.id) {
        this.$emit('onEdit', this.balance)
      } else {
        this.$emit('onAdd', this.balance)
      }
      
      this.resetFormData()
    },
    close () {
      this.resetFormData()
      this.$emit('onCancel')
    },
    resetFormData () {
      this.balance = Object.assign({}, {
        id: 0,
        amount: null,
        reference: null,
        isActive: true
      })
    },
  }
}
</script>

<style>

</style>