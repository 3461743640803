import axios from "@/axios.js"
import _ from 'lodash'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    customerNames: [],
  },
  mutations: {
    SET_CUSTOMER_NAMES(state, customerNames) {
      state.customerNames = customerNames;
    },
  },

  actions: {
    fetchCustomerNames({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = `api/giftVouchers/apps/${payload.applicationId}/customernames`
        axios.get(url)
          .then(response => {
            commit('SET_CUSTOMER_NAMES', response.data);
            if (payload.callback && typeof payload.callback === "function") {
              payload.callback()
            }
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
  }
}

