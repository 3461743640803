<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editBalance" />
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    editBalance () {
      this.params.context.componentParent.onEditBalance(this.params.data) 
    }
  }
}
</script>
